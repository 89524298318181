import ApiService from './ApiService';

export async function csrf() {
    return await ApiService.fetchData({
        url: '/sanctum/csrf-cookie',
        method: 'get',
    });
}

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: 'auth/login',
        method: 'post',
        data,
    });
}

export async function apiSignUp(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data,
    });
}

// TODO - Rota /logout não existe no back e nem recebe dados do usuário pelo front (Sem propósito)
// export async function apiSignOut(data) {
//     return ApiService.fetchData({
//         url: '/logout',
//         method: 'post',
//         data,
//     })
// }

export async function apiForgotPassword(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    });
}

export async function apiResetPassword(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    });
}
