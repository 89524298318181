import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

function useAuthority(userAuthority = {}, authority = [], emptyCheck = false) {
    const roleMatched = useMemo(() => {
        if (!userAuthority || isEmpty(userAuthority.additionalPermissions)) {
            return false;
        }

        const userPermissions = userAuthority.additionalPermissions.map(permission => ({
            accessId: permission.accessId,
            access: permission.access,
            authority: [
                permission.leitura ? 'LEITURA' : '',
                permission.escrita ? 'ESCRITA' : '',
                permission.exclusao ? 'EXCLUSÃO' : ''
            ].filter(Boolean)
        }));

        return authority?.some(permission =>
            userPermissions.some(userPermission =>
                userPermission?.accessId === permission?.accessId &&
                userPermission?.authority.includes(permission?.authority
                )
            )
        );

    }, [authority, userAuthority]);

    if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
        return !emptyCheck;
    }

    return roleMatched;
}

export default useAuthority;
