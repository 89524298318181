import {useDispatch, useSelector} from 'react-redux';
import {initialState, setUser, setAuthority} from 'store/auth/userSlice';
import {apiSignIn, apiSignUp} from 'services/AuthService';
import {onSignInSuccess, onSignOutSuccess, setToken} from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import {REDIRECT_URL_KEY} from 'constants/app.constant';
import {useNavigate} from 'react-router-dom';
import useQuery from './useQuery';
import {decodeJwt} from 'jose';

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const query = useQuery();

    const {signedIn, token} = useSelector(state => state.auth.session);

    const signIn = async values => {
        try {
            const resp = await apiSignIn(values);
            debugger;
            if (resp.data) {
                const {username} = decodeJwt(resp.data.accessToken);
                dispatch(
                    setUser({
                        userName: resp.data.access.name,
                        email: username,
                    }),
                );
                dispatch(onSignInSuccess());
                if (resp.data) {
                    dispatch(setAuthority(resp.data.permissionData));
                    dispatch(setToken(resp.data.accessToken));
                }

                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const signUp = async values => {
        try {
            const resp = await apiSignUp(values);
            if (resp.data) {
                dispatch(onSignInSuccess());
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            },
                        ),
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.errors,
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        // await apiSignOut();
        handleSignOut();
    };

    return {
        authenticated: token ? signedIn : false,
        signIn,
        signUp,
        signOut,
    };
}

export default useAuth;
