import {PERSIST_STORE_NAME} from 'constants/app.constant';
import rootReducer from './rootReducer';
import {configureStore} from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {apiSlice} from './api/apiSlice';
import {setupListeners} from '@reduxjs/toolkit/query';

const middlewares = [apiSlice.middleware];

const persistConfig = {
    key: PERSIST_STORE_NAME,
    keyPrefix: '',
    storage,
    whitelist: ['auth', 'locale', 'theme'],
    blacklist: [apiSlice.reducerPath],
};

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const persistor = persistStore(store);

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(persistReducer(persistConfig, rootReducer(store.asyncReducers)));
    persistor.persist();
    return store;
};

setupListeners(store.dispatch);

export default store;
