import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'configs/app.config';
import {onSignOutSuccess, setSessionMessage} from '../auth/sessionSlice';
const unauthorizedCode = [401, 419];

const baseQuery = fetchBaseQuery({
    baseUrl: appConfig.apiPrefix,
    credentials: 'include',
    prepareHeaders: async (headers, {getState}) => {
        const token = getState().auth.session.token; 
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');

        if (!headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }

        return headers;
    },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.data?.message) {
        api.dispatch(setSessionMessage({message: result?.data.message, type: 'success'}));
    }

    if (result?.error) {
        api.dispatch(
            setSessionMessage({message: result?.error.data.message.message, type: 'danger'}),
        );

        if (unauthorizedCode.includes(result?.error?.status)) {
            api.dispatch(onSignOutSuccess());
        }
    }

    return result;
};

export const apiSlice = createApi({
    reducerPath: 'reduxApi',
    baseQuery: baseQueryWithLogout,
    tagTypes: ['Department', 'Role', 'Contract', 'Access', 'Employee', 'Company', 'Location', 'Bank'],
    keepUnusedDataFor: process.env.NODE_ENV === 'production' ? 1 : 60,
    refetchOnFocus: process.env.NODE_ENV !== 'production',
    refetchOnReconnect: process.env.NODE_ENV !== 'production',
    endpoints: builder => ({}),
});
