import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [], // Aqui são armazenadas as funções (roles)
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {

            state.avatar = action.payload.avatar;
            state.userName = action.payload.userName;
            state.email = action.payload.email;

        },
        setAuthority: (state, action) => {
            state.authority = action.payload;
        },
        userLoggedOut: () => initialState,
    },
})



export const { setUser, setAuthority } = userSlice.actions

export default userSlice.reducer
