import axios from 'axios'
import appConfig from 'configs/app.config'
import {REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE} from 'constants/api.constant'
import {PERSIST_STORE_NAME} from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import {onSignOutSuccess, setSessionMessage} from '../store/auth/sessionSlice'

const unauthorizedCode = [401, 419]

const BaseService = axios.create({
    timeout: 60000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    baseURL: appConfig.apiPrefix,
    withCredentials: true,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData.auth.session.token

        if (!accessToken) {
            const {auth} = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
                ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => {
        if (response.data.message) {
            store.dispatch(setSessionMessage({message: response.data.message, type: 'success'}))
        }

        return response
    },
    (error) => {
        const {response} = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        store.dispatch(setSessionMessage({message: response.data.message, type: 'danger'}))

        return Promise.reject(error)
    }
)

export default BaseService
